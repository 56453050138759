import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import copy from 'copy-text-to-clipboard'
import { FiCopy } from 'react-icons/fi'

import Layout from "../components/layout"
import SEO from "../components/seo"

const PayPage = () => {
  return (
    <Layout>
      <SEO title="Jason Sebring :: the Jason Sebring that owns this domain" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20
      }}>
          <div><StaticImage alt="ethqr" src="../images/ethqr.png" /></div>
          <div onClick={() => copy('0x866D922B5e4167c453EFD2146639d4Ff3BD78836')} style={{
              borderRadius: 5,
              backgroundColor: '#fff',
              padding: 5,
              fontSize: 10,
              marginTop: 10,
              color: '#000',
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center'
          }}>0x866D922B5e4167c453EFD2146639d4Ff3BD78836 <FiCopy color="#000" size={14} /></div>
      </div>
    </Layout>
  )
}

export default PayPage
